import React from "react"
import AlpabeticalSorter from "./AlphabeticalSorter"
import "./AlphabeticalList.scss"

const AlphabeticalList = ({
  items,
  headline
}) => {

  return (
    <div className="container section section-alphabetical-list">
      <div className="container">
        <div className="row">
          <div className="col-12 pt-1 pb-2">
            {
              headline && <h2 className="bracket-text">{headline}</h2>
            }
          </div>
        </div>
        <div className="row">
          <div className="col-12 item-column">
            <AlpabeticalSorter asGroup={items} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlphabeticalList
