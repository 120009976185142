/* eslint-disable no-unused-vars */
import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import SorterItem from "./SorterItem"

const AlphabetSorter = ({
  itemOptions,
  labelKey,
  asGroup,
  chunkByLetter,
  navigator
}) => {

  const buildItem = (item, index, isNavigator) => {
    return (
      <SorterItem
        itemOptions={itemOptions}
        isNavigator={isNavigator}
        labelKey={labelKey}
        item={item}
        key={index + item.link}
      />
    )
  }

  const isNavigator = (arr, index, item) => {

    if (index > 0) {
      const currentNavigator = item[labelKey].charAt(0).toUpperCase()
      const prevNavigator = arr[index - 1][labelKey].charAt(0).toUpperCase()

      return navigator && currentNavigator !== prevNavigator
    }

    return navigator
  }

  const alphaChunk = (sortedArr) => {
    const result = []
    let pos = 0

    for (let i = 0; i <= sortedArr.length; i += 1) {
      if (result[pos] === undefined) {
        result.push([sortedArr[i]])
        /* eslint-disable-next-line */
        continue
      }

      const last = result[pos][result[pos].length - 1]

      if (!!last && !!sortedArr[i] && last[labelKey].charAt(0).toLowerCase() === sortedArr[i][labelKey].charAt(0).toLowerCase()) {
        result[pos].push(sortedArr[i])
      } else if (sortedArr[i]) {
        result.push([sortedArr[i]])
        pos += 1
      }
    }

    return result
  }

  const alphabeticalSort = (a, b) => {
    const A = a[labelKey].toLowerCase()
    const B = b[labelKey].toLowerCase()

    if (A < B) {
      return -1
    /* eslint-disable-next-line */
    } else if (A > B) {
      return 1
    } else {
      return 0
    }
  }


  let sortedArr = [...asGroup].sort(alphabeticalSort)

  const cns = {
    items: cn(
      "as-items",
      !!navigator && "as-items--navigator"
    ),
  }

  if (chunkByLetter) {
    sortedArr = alphaChunk(sortedArr)
  }

  return (
    <div className={cns.items}>
      <ul className="as-items__list">
        { sortedArr.map((item, index) => buildItem(item, index, isNavigator(sortedArr, index, item))) }
      </ul>
    </div>
  )
}

AlphabetSorter.propTypes = {
  itemOptions: PropTypes.objectOf(Object),
  labelKey: PropTypes.string,
  navigator: PropTypes.bool,
  chunkByLetter: PropTypes.bool,
}

AlphabetSorter.defaultProps = {
  itemOptions: {},
  labelKey: "title",
  navigator: true,
}

export default AlphabetSorter
