import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

const SorterItem = (props) => {

  const {
    item,
    labelKey,
    isNavigator,
    itemOptions,
  } = props


  const linkOptions = {
    href: item.link,
    target: "_blank",
  }

  const buildContent = () => {

    return (
      item.link ?
        (<a {...linkOptions} className="text-md">{ `${item[labelKey]}` }</a>) : (
          <span {...itemOptions} className="text-md">
            { `${item[labelKey]}` }
          </span>
        )
    )
  }

  const label = item[labelKey]

  const cns = {
    item: cn(
      'as-item',
      {'as-item--navigator': isNavigator}
    ),
  }

  return (
    <li className={cns.item}>
      { isNavigator && (
        <div className="as-item__navigator text-md">
          { `(${label.charAt(0).toUpperCase()})` }
        </div>
        )
      }

      <div className="as-item__content">
        { buildContent() }
      </div>
    </li>
  )
}

SorterItem.propTypes = {
  isNavigator: PropTypes.bool,
  labelKey: PropTypes.string,
  itemOptions: PropTypes.objectOf(Object)
}

export default SorterItem
